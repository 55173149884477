// node_modules dependencies
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

// Vuex
import Store from '@/store'

@Component
export default class Sidebar extends Vue {
  constructor() {
    super()
  }

  tile: boolean = false
  logoImage: any = require('../../assets/img/logo-icon.png')
  logoSize: string | number = '20px'
  width: string | number = 250

  isSuperAdmin: boolean = JSON.parse(window.localStorage.getItem('is_superadmin'))

  @Prop({ default: true })
  clipped: boolean

  @Prop({ default: false })
  fixed: boolean

  @Prop({ default: {} })
  drawerItems: object

  @Prop({ default: false })
  miniVariant: boolean

  @Prop({ default: true })
  right: boolean

  @Prop({ default: false })
  rightDrawer: boolean

  @Prop({ default: true })
  app: boolean

  @Prop({ default: true })
  resizeWatcher: boolean

  @Prop({ default: true })
  dark: boolean

  get sidebarMiniVariant() {
    return Store.state.sidebarMiniVariant
  }

  set sidebarMiniVariant(value) {
    Store.commit('changeSidebarMiniVariant', value)
  }

  get drawerState() {
    return Store.state.drawerState
  }

  set drawerState(value) {
    Store.commit('changeDrawerState', value)
  }

  goTo(routerInstance: any, url: string): void {
    routerInstance.push(url)
  }

  onListItemClick(url: string) {
    this.goTo(this.$router, url)
    // this.sidebarMiniVariant = true
  }
}
