export const sidebarItems = [
  {
    title: 'Dashboard',
    icon: 'dashboard',
    url: '/dashboard',
    active: true,
    requireSuperAdmin: false
  },
  // {
  //   title: 'Request Goods',
  //   icon: 'shopping_cart',
  //   url: '/request-goods',
  //   active: false,
  //   requireSuperAdmin: false
  // },
  {
    title: 'Orders',
    icon: 'book',
    url: '/#',
    active: false,
    requireSuperAdmin: false,
    items: [
      {
        title: 'Add New Order',
        url: '/orders/create',
        active: false,
        requireSuperAdmin: false
      },
      // {
      //   title: 'Add New Order - Non Catalog',
      //   url: '/orders/createOrderNonCatalog',
      //   active: false,
      //   requireSuperAdmin: false
      // },
      {
        title: 'Order Lists',
        url: '/orders',
        active: false,
        requireSuperAdmin: false
      }
      // {
      //   title: 'Invoices',
      //   url: '/invoices',
      //   active: false,
      //   requireSuperAdmin: false
      // }
    ]
  },
  // {
  //   title: 'Billings',
  //   icon: 'receipt',
  //   url: '/billings',
  //   active: false,
  //   requireSuperAdmin: false
  // },
  {
    title: 'Products',
    icon: 'shopping_basket',
    url: '/#',
    active: false,
    requireSuperAdmin: false,
    items: [
      {
        title: 'Add New Product',
        url: '/products/create',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Product Lists',
        url: '/products',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Suppliers',
        url: '/suppliers',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Brands',
        url: '/brands',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Warehouses',
        url: '/warehouses',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Manage Category',
        url: '/ali-2-manage-category',
        active: false,
        requireSuperAdmin: true
      }
    ]
  },
  {
    title: 'Promotions',
    icon: 'mdi-sale',
    url: '/#',
    active: false,
    requireSuperAdmin: false,
    items:[
      {
        title: 'Promo Deals',
        url: '/promo-deals',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Banner Mobile',
        url: '/banner',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Custom Menu Mobile',
        url: '/custom-menu',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Notifications',
        url: '/notifications',
        active: false,
        requireSuperAdmin: true
      }
    ]
  },
  {
    title: 'Shipments',
    icon: 'flight',
    url: '/#',
    active: false,
    requireSuperAdmin: false,
    items: [
      {
        title: 'Shipment by courier',
        url: '/shipment-courier',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Shipment by ACN',
        url: '/shipment-acn',
        active: false,
        requireSuperAdmin: false
      }
    ]
  },
  {
    title: 'Custom Pages',
    icon: 'mdi-application',
    url: '/#',
    active: false,
    requireSuperAdmin: false,
    items: [
      {
        title: 'Add New Custom Pages',
        url: '/#',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Custom Pages Lists',
        url: '/#',
        active: false,
        requireSuperAdmin: false
      }
    ]
  },
  {
    title: 'User Settings',
    icon: 'settings',
    url: '/#',
    active: false,
    requireSuperAdmin: true,
    items: [
      {
        title: 'Manage Users',
        url: '/manage-user',
        active: false,
        requireSuperAdmin: true
      },
      {
        title: 'Manage Referral',
        url: '/manage-referral',
        active: false,
        requireSuperAdmin: true
      },
      {
        title: 'Manage Agent',
        icon: 'person',
        url: '/manage-agent',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Manage Partner',
        icon: 'person',
        url: '/manage-partner',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Manage Commission',
        icon: 'monetization_on',
        url: '/manage-commission',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Manage Limitation',
        url: '/manage-limitation',
        active: false,
        requireSuperAdmin: true
      }
    ]
  },
  {
    title: 'Others',
    icon: 'more_horiz',
    url: '/#',
    active: false,
    requireSuperAdmin: false,
    items: [
      {
        title: 'Area Lists',
        url: '/area-lists',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Port Lists',
        url: '/port-lists',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Country Lists',
        url: '/country-list',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Province Lists',
        url: '/province-list',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'City Lists',
        url: '/city-list',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Currencies',
        url: '/currencies',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Review Lists',
        url: '/review-list',
        active: false,
        requireSuperAdmin: false
      }
    ]
  },
  {
    title: 'ALI-2 Settings',
    icon: 'mdi-arch',
    url: '/#',
    active: false,
    requireSuperAdmin: false,
    items: [
      {
        title: 'HS Codes',
        url: '/hscodes',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'International Shipping Rate',
        url: '/ali-2-international-shipping',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Domestic Shipping Rate',
        url: '/ali-2-domestic-shipping',
        active: false,
        requireSuperAdmin: true
      },
      {
        title: 'Category Rules',
        url: '/ali-2-category-rules',
        active: false,
        requireSuperAdmin: true
      },
      {
        title: 'Product Rules',
        url: '/ali-2-product-rules',
        active: false,
        requireSuperAdmin: true
      },
      {
        title: 'Category Mapping',
        url: '/import-wizard',
        active: false,
        requireSuperAdmin: false
      },
      {
        title: 'Payment Settings',
        url: '/ali-2-payment-settings',
        active: false,
        requireSuperAdmin: true
      }
    ]
  }
  // {
  //   title: 'Warehouses',
  //   icon: 'store',
  //   url: '/#',
  //   active: false,
  //   requireSuperAdmin: false,
  //   items: [
  //     {
  //       title: 'Warehouse Lists',
  //       url: '/warehouses',
  //       active: false,
  //       requireSuperAdmin: false
  //     },
  //     {
  //       title: 'Inbounds',
  //       url: '/inbounds',
  //       active: false,
  //       requireSuperAdmin: false
  //     }
  //   ]
  // },
  // {
  //   title: 'Quality Controls',
  //   icon: 'verified_user',
  //   url: '/quality-control',
  //   active: false,
  //   requireSuperAdmin: false
  // },
]
