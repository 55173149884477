// node_module dependencies
import Component from 'vue-class-component'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { State } from 'vuex-class'

// Base Page
import BasePage from '@/pages/Base'

// Local Components
import Sidebar from '@/components/Sidebar/Sidebar.vue'
import Navbar from '@/components/Navbar/Navbar.vue'
import Loading from '@/components/Loading/Loading.vue'

// Services
import AuthService from '@/services/Auth'
import ProfileService from '@/services/Profile'

// Mixins
import { sidebarItems } from '@/mixins/sidebarItems'

@Component({
  components: {
    'ac-sidebar': Sidebar,
    'ac-navbar': Navbar,
    Loading
  }
})
export default class MainPage extends BasePage {
  constructor() {
    super()
  }

  /* DATA */
  avatarSize: string | number = '36px'
  avatarIcon: string = 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460'
  avatarName: string = ''
  avatarEmail: string = ''
  avatarMenu: boolean = false

  // toolbar
  toolbarFixed: boolean = true
  toolbarApp: boolean = true
  toolbarFlat: boolean = true
  toolbarLight: boolean = true
  toolbarDense: boolean = false
  toolbarLogo: string = 'https://qa.asiacommerce.net/images/AsiaCommerce-Logo-Color-350x72.png'
  isSearching: boolean = false

  // sidebar
  sidebarItems: object[] = sidebarItems
  drawerClipped: boolean = false
  drawerApp: boolean = true

  // footer
  footerApp: boolean = true
  footerFixed: boolean = false

  /* VUEX */
  @State('snackbar')
  snackbar
  @State('pageTitle')
  pageTitle
  @State('breadcrumbs')
  breadcrumbs
  @State('loading')
  loading

  set drawerMini(value) {
    this.$store.commit('changeSidebarMiniVariant', value)
  }
  get drawerMini() {
    return this.$store.state.sidebarMiniVariant
  }

  get thisYear() {
    return new Date().getFullYear()
  }

  mounted() {
    this.getProfileData()
  }

  logout() {
    AuthService.logout()
  }

  async getProfileData() {
    try {
      const response = await ProfileService.getProfileData()
      // console.log('user',response)
      // // jika yang login tidak memiliki roles maka ditendang
      // const isSuperAdmin = find(response.data.user_data.roles.data, role => role.slug === 'superadmin')

      this.avatarName = response.data.attributes.extras.full_name
      this.avatarEmail = response.data.attributes.extras.email
      this.avatarIcon = response.data.attributes.extras.gravatar_image

      // this.avatarName = response.data.attributes.name
      // this.avatarEmail = response.data.attributes.email
      // this.avatarIcon = response.data.attributes.extras.gravatar_image

      // window.localStorage.setItem('user_data', JSON.stringify(response))
      // // console.log(response.data.roles)
      // // const isSuperAdmin = 'superadmin'
      // // console.log('aaa')
      // window.localStorage.setItem('is_superadmin', `${!isEmpty(isSuperAdmin)}`)      

    } catch (error) {
      // console.log('in')
      this.catchHandler(error)
    }
  }

  goTo(url: string) {
    this.$router.push(url)
  }
}
