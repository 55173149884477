import { render, staticRenderFns } from "./loading.pug?vue&type=template&id=31649737&scoped=true&lang=pug&"
import script from "./loading.ts?vue&type=script&lang=ts&"
export * from "./loading.ts?vue&type=script&lang=ts&"
import style0 from "./loading.scss?vue&type=style&index=0&id=31649737&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31649737",
  null
  
)

export default component.exports