// node_modules dependencies
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class Navbar extends Vue {
  constructor() {
    super()
  }

  search: string = ''

  get searching() {
    return this.isSearching
  }
  set searching(value) {
    this.isSearching = value
  }

  @Prop({ default: true })
  toolbarFixed: boolean

  @Prop({ default: '' })
  toolbarTitle: string

  @Prop({ default: true })
  toolbarApp: boolean

  @Prop({ default: true })
  toolbarFlat: boolean

  @Prop({ default: true })
  toolbarLight: boolean

  @Prop({ default: true })
  toolbarDense: boolean

  @Prop({ default: '' })
  toolbarLogo: string

  @Prop({ default: false })
  toolbarDrawer: boolean

  @Prop({ default: false })
  isSearching: boolean

  drawerClick() {
    this.$emit('onDrawerClick')
  }
  cancelSearch() {
    this.searching = false
    this.$emit('onCancelSearch')
  }
}
